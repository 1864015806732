import { graphql } from 'gatsby';
import React from 'react';

import { Layout } from '../containers/layout';
import { Modal } from '../containers/modal';
import { LeistungenTemplate } from '../pageTemplates/leistungen';
import { GraphQLData } from '../types';

interface LeistungenProps {
  data: GraphQLData;
  location: any;
}

export const Leistungen = ({ location, data }: LeistungenProps) => {
  return (
    <Layout>
      <LeistungenTemplate data={data} location={location} />
      <Modal services />
      <Modal team level="2" />
    </Layout>
  );
};

export const query = graphql`
  query Leistungen {
    strapi {
      service {
        meta {
          title
          description
        }
        subheader
        disciplineTitle
        disciplineSubtitle
        methodsTitle
        methodsSubtitle
        diseaseTitle
        competencyCover {
          alternativeText
          url
          file {
            ...Image
          }
        }
        methodCover {
          alternativeText
          url
          file {
            ...Image
          }
        }
        pricingTitle
        pricingSubtitle
        diseaseInformation
        diseases {
          order
          title
        }
      }
      disciplines(sort: "order:ASC") {
        id
        link
        cover {
          alternativeText
          url
          file {
            ...Image
          }
        }
        content {
          ... on STRAPI_ComponentArticleImage {
            id
            image {
              alternativeText
              url
              file {
                ...Image
              }
            }
          }
          ... on STRAPI_ComponentArticleText {
            id
            text
          }
        }
        teaser
        title
      }
      methods(sort: "order:ASC") {
        id
        link
        id
        teaser
        title
        cover {
          alternativeText
          url
          file {
            ...Image
          }
        }
      }
      therapists(sort: "order:ASC") {
        id
        title
        forename
        order
        surname
        pricings {
          minutes
          price
        }
        image {
          alternativeText
          url
          file {
            ...Image
          }
        }
      }
    }
  }
`;

export default Leistungen;
